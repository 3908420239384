@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
  margin: 0;
  padding: 20px;
  font-family: 'Rubik';
  color: #FCE572;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCE572;
}

#boobGif {
  margin: 0px;
  width: 350px;
  height: 350px;
  padding: 10px;
}
#tagline {
  color: purple;
}

.main-info {
  background-color: rgb(122, 27, 118);
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "poppins-light";
  border: 4px solid #F89B56;
}
.main-text {
  margin: 15px;
  height: 50%;
  width: 90%;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}

.mint-text {
  margin: 35px;
  float: left;
  height: 40%;
  width: 90%;
  padding: 10px;
  flex-grow: 1;
}

#mint-info-header {
  font-family: "coasterBlack";
  font-size: 25px;
  text-decoration: underline;
  width: 40%;
}

.titty-text {
  width: 550px;
  margin: auto;
  margin-bottom: 45px;
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #F89B56;
  color: #F15E56;
  background-color: #FCE572;
  border-radius: 8px;
  /* Center */
  display: block;
  /* margin-left: auto; */
  margin: auto;
  /* Fonts */
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
  /* width: 200px;
  justify-items: center; */
}

.main-mint {
  /* width: 40%; */
  margin: auto;  
  justify-content: center;
}

/* Small screens */
@media all and (max-width: 500px) {
  /* #button {
    width: 200px;
  } */
   .buttons {
    width: 300px;
    /* display: flex; */
    margin: auto;
   }
  /*
    left: 50%; 
    display: column;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -25%;
  } */

  ul.header li a {
    font-size: 80%;
  }

  .main-info {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
    margin: 0px;

  }

  .main-text {
    margin: 0px;
  }
  #boobGif {
    margin: 20px;
    width: 80%;
  } 

  .titty-text {
    width: 80%;
    margin: 20px;
    margin-bottom: 45px;
  }
  

  .mint-info{
    padding: 10px;
  }

  .mint-text{
    width: 80%;
    margin: 0px;
  }

  #address{ 
    font-size: 75%;
    align-self: left;
  }

  #tagline {
    font-size: 95%;
  }
}


.charity-div {
  background-color: rgb(122, 27, 118);
  display: flex;
  justify-content: right;
  border: 4px solid #F89B56;
}

.charity-text {
  margin: auto;
  width: 70%;
}

#sideboob-right {
  width: 20%;
  grid-gap: 0px 2000px;
  gap: 0px 2000px;
  transform: scaleX(-1);
}

#sideboob-left {
  width: 20%;
  grid-gap: 0px 2000px;
  gap: 0px 2000px;
}

.dao-info {
  margin: 20px;
  width: 70%;
  vertical-align: center;
}

.header {
  text-align: center;
}

/* .debug {
  border: 3px solid #73AD21;
} */

.hidden {
  background-color: rgb(122, 27, 118);
  text-align: center;
  color: rgb(122, 27, 118);
  display: flex;
  padding: 20px;
  place-content: center;
  border: 4px solid #F89B56;
}

.main-image {
  width: 99%;
  border: 4px solid rgb(122, 27, 118);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.PresaleRegistration {
  max-width: 80vh;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: center;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #F89B56;
  color: #F15E56;
  background-color: #FCE572;
  border-radius: 8px;
  /* Center */
  display: block;
  margin: auto;
  /* Fonts */
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

#title {
  padding-top: 32px;
}

#status {
  color:red;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: right;
  float:right;
  margin-bottom: 50px;
  line-height: 16px;
}

#mintButton {
  margin-top: 40px;
}

h1, h2, ul, li {
  /* font-family: sans-serif; */
  font-family: 'coasterBlack';
  /* src: local('coaster.black'), url(./fonts/coaster.black.ttf) format('truetype'); */
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg'*/
}

p {
  font-family: 'poppins-light';
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
  font-family: "coasterBlack";
  font-size: 25px;
}
ul.header {
  background-color: rgb(122, 27, 118);
  padding: 0;
}

ul.header li a {
  color: #FCE572;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}


.content h2 {
  padding: 0;
  margin: 0;
}

.content li {
  margin-bottom: 10px;
  font-family: "poppins-light";
}

.content {
  overflow-y: auto;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'coasterBlack';
  src: local('coaster.black'), url(/static/media/coaster.black.234407c8.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
  'embedded-opentype', and 'svg'*/
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'coasterShadow';
  src: local('coaster.shadow'), url(/static/media/coaster.shadow.25e2ced4.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
  'embedded-opentype', and 'svg'*/
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: 'poppins-light';
  src: local('Poppins-Light'), url(/static/media/Poppins-Light.f41b63c0.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
  'embedded-opentype', and 'svg'*/
}
.App {
  text-align: center;
  background-color: rgb(122, 27, 118);
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mint-info {
  color: #FCE572;
  font-weight: bold;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  text-align: left;
  border: 4px dashed #FCE572;
}
#mint-info-header {
  font-family: "poppins-light";
  font-size: 20px;
  text-decoration: underline;
  text-align: center
}

.mintButtons {
  margin: 0 auto;
  text-align: center;
}

#mintInput {
  margin: auto;
  width: 100%;
  margin: 0;
  text-align: center;
  margin-bottom:5px;
}

.main-mint {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

#mintInputField {
  margin: auto;
  width: 35%;
  margin: 0;
  text-align: center;
  background-color: #FCE572;
  margin-bottom:5px;
}
#mintButton {
  margin: auto;
  width: 50%;
  margin: 0;
  text-align: center;
}
#walletConnectButton {
  margin: 0;
  width: 50%;
}

#dropdown{
    /* display: flex; */
    margin: 0 auto;
    padding: 25px;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: rgb(122, 27, 118);
  padding: 0;
}

ul.header li a {
  color: #FCE572;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FCE572;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

